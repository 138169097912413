import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import Contact from "../components/forms/ContactForm";
import components from "../utils/componentsForPortableText";
import sanityConnectInfo from "../../sanityConnectInfo.json";
import SupportBand from "../components/general/SupportBand";

const ContactStyles = styled.div`
  .content {
    padding-left: 10%;
    padding-right: 10%;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    .intro {
      margin-bottom: 3rem;
      display: grid;

      h1 {
        text-align: center;
      }

      h2 {
        margin: 0;
      }

      img {
        justify-self: center;
        margin-bottom: 2rem;
      }
    }
  }

  .signup {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export function Head({ location, ...props }) {
  return (
    <SearchEngineOptimization
      {...props}
      meta={{ title: "Contact" }}
      location={location}
    />
  );
}

export default function ContactPage({
  data: { section, paymentGateways },
  location,
}) {
  return (
    <ContactStyles>
      <div className="content">
        <div className="intro">
          <h1>{section.title}</h1>

          <PortableText
            value={section.textEditor._rawRichText}
            projectId={sanityConnectInfo.projectId}
            dataset={sanityConnectInfo.dataset}
            components={components}
          />
        </div>
      </div>

      <Contact />

      <div className="bottom-support-band">
        <SupportBand support={paymentGateways} />
      </div>
    </ContactStyles>
  );
}

export const query = graphql`
  query {
    section: sanitySection(title: { eq: "Contact" }) {
      title

      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
    }

    paymentGateways: sanitySection(title: { eq: "Support The Band" }) {
      textEditor {
        _rawRichText(resolveReferences: { maxDepth: 10 })
      }
      title
    }
  }
`;
