import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useStaticQuery, graphql } from "gatsby";
import { PortableText } from "@portabletext/react";

import sanityConnectInfo from "../../../sanityConnectInfo.json";

const ContactStyles = styled.div`
  position: relative;
  margin-top: 4rem;

  .contact-background-image {
    position: absolute;
    bottom: 0;
    z-index: -10;
  }

  form {
    display: grid;
    grid-row-gap: 1rem;

    .password {
      display: none;
    }

    button {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 700px) {
    form {
      input,
      textarea {
        width: 85%;
      }
    }
  }

  .contact-text p {
    color: var(--black);
    font-weight: bold;
  }

  .notify {
    z-index: 9999;

    &.notify-visible {
      transform: translateX(0);
      transition-timing-function: ease-out;
      transition: 0.8s;
    }

    &.notify-invisible {
      transform: translateX(-130%);
    }
  }
`;

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");

  const [travelBlogs, setTravelBlogs] = useState({});
  const [familyLifeBlogs, setFamilyLifeBlogs] = useState({});

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [youtubeChannels, setYoutubeChannels] = useState([]);

  const [notify, setNotify] = useState("");
  const notifyTimeout = useRef();

  const data = useStaticQuery(graphql`
    query {

      bigcommerce: allBigCommerce(filter: { is_featured: { eq: true } }) {
        edges {
          node {
            primary_image {
              url_standard
              description
            }
            custom_url {
              url
            }
            name
          }
        }
      }

      youtube: allSanityChannel {
        nodes {
          title
          image {
            image {
              asset {
                url
              }
            }
          }
          url
        }
      }

      travelBlogs: allSanityBlog(
        filter: {
          pillar: { eq: true }
          blogCategory: { slug: { current: { eq: "travel" } } }
        },
        limit: 2
      ) {
        nodes {
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          title
          slug {
            current
          }
          description
        }
      }

      familyLifeBlogs: allSanityBlog(
        filter: {
          pillar: { eq: true }
          blogCategory: { slug: { current: { eq: "family-life" } } }
        },
        limit: 2
      ) {
        nodes {
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          title
          slug {
            current
          }
          description
        }
      }
    }
  `);

  const beginNotifyCountdown = () => {
    notifyTimeout.current = setTimeout(() => {
      setNotify({});
    }, 5000);
  };

  useEffect(() => {
    getFeaturedProducts({ data });
    getYoutubeChannels({ data });

    getTravelBlogs({ data });
    getFamilyLifeBlogs({ data });

    beginNotifyCountdown();

    return () => {
      clearTimeout(notifyTimeout);
    };
  }, [data]);

  const getFeaturedProducts = ({ data }) => {
    const products = [];

    data.bigcommerce.edges.forEach((product) => {
      const featured = {
        productImage: product.node.primary_image.url_standard,
        productDescription: product.node.primary_image.description,
        productUrl:
          "https://shop.familyilove.com" + product.node.custom_url.url,
        productTitle: product.node.name,
      };

      products.push(featured);
    });

    setFeaturedProducts(products);
  };

  const getYoutubeChannels = ({ data }) => {
    const youtube = [];

    data.youtube.nodes.forEach((channel) => {
      const youtubeChannels = {
        youtubeTitle: channel.title,
        youtubeUrl: channel.url,
        youtubeImage: channel.image.image.asset.url,
      };

      youtube.push(youtubeChannels);
    });

    setYoutubeChannels(youtube);
  };

  const getTravelBlogs = ({ data }) => {
    const posts = {};

    data.travelBlogs.nodes.forEach((post, i) => {
      const blogPost = {
        image: {
          url: post.image.image.asset.url,
          alt: post.image.alt,
        },
        description: post.description,
        linkUrl: "https://familyilove.com/" + post.slug.current,
        title: post.title,
      };

      posts[`blog${i + 1}`] = blogPost;
    });

    setTravelBlogs(posts);
  };

  const getFamilyLifeBlogs = ({ data }) => {
    const posts = [];

    data.familyLifeBlogs.nodes.forEach((post, i) => {
      const blogPost = {
        image: {
          url: post.image.image.asset.url,
          alt: post.image.alt,
        },
        description: post.description,
        linkUrl: "https://familyilove.com/" + post.slug.current,
        title: post.title,
      };

      posts[`blog${i + 1}`] = blogPost;
    });

    setFamilyLifeBlogs(posts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      name,
      email,
      message,
      password,

      travelBlogs,
      familyLifeBlogs,

      featuredProducts,
      youtubeChannels,
    };

    axios
      .post(
        `${process.env.GATSBY_SERVERLESS_BASE}/sendContactMail`,
        JSON.stringify(body)
      )
      .then((res) => {
        setName("");
        setEmail("");
        setMessage("");
        setPassword("");

        setNotify({ msg: res.data, color: "#028703" });
        beginNotifyCountdown();
      })
      .catch((err) => {
        setNotify({
          msg: err.response
            ? err.response.data
            : "Something went wrong! Please try to send a message again!",
          color: "#f01f1f",
        });
        beginNotifyCountdown();
      });
  };

  return (
    <ContactStyles>
      <div
        className={`notify ${notify.msg ? "notify-visible" : "notify-invisible"
          }`}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: notify.color,
          color: "white",
          padding: "0 20px",
          zIndex: 9999,
        }}
      >
        <p>{notify.msg}</p>
      </div>

      <div id="contact" className="contact bkg-color">

        <form onSubmit={handleSubmit}>
          <fieldset>
            <input
              name="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <input
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <textarea
              name="message"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </fieldset>

          <fieldset className="password">
            <input
              name="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <button type="submit">Send Message</button>
          </fieldset>
        </form>
      </div>
    </ContactStyles>
  );
};

export default ContactForm;
